.footer__container{
    height: 290px;
    width: 100%;
    background-color: #030e1e;
    color: #e2e8f1;
    padding: 50px 0px;
    border-top: 4px solid #d1d1d1;
}

.footer__companyName{
    text-align: center;
    letter-spacing: 2px;
    font-weight: 600;
    font-size: 25px;
}

.copyright{
    text-align: center;
    margin: 18px 0px 18px 0px;
    font-size: 12px;
    letter-spacing: 2px;
    font-weight: 600;
}

.footer__address{
    text-align: center;
    margin: 18px 20px 20px 20px;
    letter-spacing: 2px;
    font-size: 11px;
}

.footer__privacyPolicy{
    text-align: center;
    font-size: 10px;
    letter-spacing: 2px;
}

.footer__privacyPolicy a{
    text-decoration: none;
    color: #e2e8f1;
}

.footer__privacyPolicy a:hover{
    color: #cfb381;
}

.footer__icons{
    display: flex;
    flex-direction: row;
    gap: 30px;
    font-size: 25px;
    justify-content: center;
    margin-bottom: 20px;
}

.footer__icons i{
    transition: .3s;
}
.footer__icons i:hover{
    transform: translateY(5px);
}