.row{
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.col{
    padding-left: 0 !important;
    padding-right: 0!important;
}

*{
    scroll-behavior: smooth;
}


.background__img{
    background: linear-gradient(rgba(0,0,0,.1),rgba(0,0,0,.9)), url(../assests/images/homeImg.jpg);
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    /* border-bottom: 10px solid #ababab; */
    margin-bottom: 70px;
}

.home__content{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.home__text{
    text-align: center;
    /* padding-top: 200px; */
    text-align: center;
    color: #fff;
    font-size: 1.rem;
    margin: 0px 10px 0px 10px;
    letter-spacing: 1.7px;
    font-weight: 600;
    font-size: 30px;
}

.searchBox{
    width: 300px;
    background: white;
    height: 50px;
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
}

.search_icon{
    width: 20%;
    height: 80%;
    background-color: black;
    display: block;
    margin-left: auto;
    margin: 6px 10px 6px auto;
    border-radius: 5px;
}

.search__field{
    outline: none;
    border: none;
    width: 60%;
    height: 80%;
    display: block;
    margin: 6px 16px 6px 24px;
    border-radius: 5px;
    letter-spacing: 2px;
    font-weight: 600;
}

.search_icon i{
    color: white;
    font-size: 24px;
    display: flex;
    justify-content: center;
}

.background__img p{
    text-align: center;
    margin: 30px;
    color: #e5e4e4;
    font-size: 20px;
}

.whatsAppIcon{
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 99;
    font-size: 35px;
    margin: 20px;
    color: #00ff00;
    transition: .3s;
}

.whatsAppIcon:hover{
    color: black;
    transform: translateY(5px );
}

.aboutUs__title{
    text-align: center;
    font-weight: 600;
    padding-top: 100px;
    padding-bottom: 20px;
    color: #cfb381;
}

@media screen and (min-width:992px){
    .aboutUs__title{
        margin-bottom: 30px;
    }
}

.aboutUs__text{
    text-align: center;
    /* text-align: justify; */
    margin: 45px 40px 0px 40px;
    font-weight: 600;
    font-size: 20px;
    color: #d3d8db;
    letter-spacing: .2px;
}

.aboutUs__content{
    margin-bottom: 50px;
    margin-top: 20px;
}

@media screen and (min-width:992px){
    .aboutUs__content{
        margin-bottom: 100px;

    }
}
@media screen and (max-width:992px) {
    .aboutUs__text{
        margin: 30px 20px 40px 20px;
    }
}

.aboutus__Img img{
    width: 250px;
    display: block;
    margin: auto;
    border-radius: 10px;
}

@media screen and (min-width:992px){
    .aboutus__Img img{
        width: 300px;
    }
}

.aboutus__Img {
    border-left: inset #ababab54;
    height: 100%;
}

@media screen and (max-width:992px){
    .aboutus__Img img{
        margin-top: 60px;
    }
}

@media screen and (max-width:770px){
    .aboutus__Img{
        border-top: inset #ababab54;
        border-left: none;
        margin: 40px 38px 40px 38px;
    }
}




@media (min-width:575px) and (max-width:900px){
    .aboutus__Img{
        border-left: inset #ababab54;
        border-top: none;
        height: 74%;
    }
}

.products_div{
    background-color: #e9e9e9;
    padding-bottom: 50px;
}

.products_div h2{
    text-align: center;
    font-weight: 600;
    font-size: 25px;
    margin: 30px;
}

.products__title{
    text-align: center;
    font-weight: 600;
    margin: 100px 0px 10px 0px;
    font-size: 17px;
}

.quality{
    text-align: center;
    margin-top: 20px;
    font-weight: 600;
    font-size: 17px;
    color: #cfb381;
}

.quality i{
    margin: 0px 10px 0px 0px;
}

.services__title{
    text-align: center;
    font-weight: 600;
    padding-top: 100px;
    padding-bottom: 25px;
    color: #e2e8f1;
}

.services{
    text-align: center;
    height: 300px;
    padding: 30px;
    border-radius: 10px;
    margin: 10px 14px 25px 14px;
    transition: .3s ease-in;    
    background: linear-gradient(162deg, #0a85df,#4884b6, transparent);
    /* background: linear-gradient(176deg, #36a4e9, transparent); */
}

@media screen and (max-width:767px){
    .services{
        padding: 40px;
    }
}

@media screen and (min-width:768px) and (max-width:1024px) {
    .services{
        padding: 30px;
        height: 330px;
    }
}

.section-services{
    background: #2a6692;
    padding-bottom: 100px;
}

.services__head{
    font-weight: 600;
    color: #e2e8f1;
    letter-spacing: .2px;
    margin: 10px;
    font-size: 1.1rem;
}

.services i{
    font-size: 25px;
}

.services p{
    font-weight: 600;
    color: #9eb9cc;
    letter-spacing: .2px;
    font-size: 18px;
}

.services:hover{
    box-shadow: rgba(0,0,0,0.32)0px 4px 7px,rgba(0,0,0,0.28) 0px 3px 4px;
}

.testimonial__title{
    text-align: center;
    /* font-weight: 600; */
    padding-top: 100px;
    color: #e2e8f1;
    font-size: 14px;
    letter-spacing: 2px;
}

@media screen and (min-width:800px){
    .testimonial__title{
        padding-top: 140px;
    }
}

.fssai_logo{
    width: 170px;
    margin: 50px auto;
    display: block;
}

.fssai h4{
    margin: 20px;
    font-size: 20px;
    text-align: center;
    color: #d3d8db;
}

.fssai span{
    color: #cfb381;
}
.contactUs__title{
    text-align: center;
    font-weight: 600;
    padding-bottom: 20px;
    padding-top: 100px;
    color: #cfb381;
}

.companyname__contactUs{
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1px;
    margin: 30px 20px 20px 30px ;
    color: #cfb381;
}

.contactUs__details{
    margin: 20px 20px 30px 30px ;
    font-weight: 600;
    color: #d3d8db;
    letter-spacing: .2px;
    font-size: 18px;
}



.contactUs__socilaMedia{
    margin: 20px 20px 30px 30px ;
    font-size: 20px;
    font-weight: 600;
    color: #d3d8db;
    letter-spacing: 1px;
}

.contactUs__icons{
    gap: 20px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin: 20px 20px 30px 30px;
    font-size: 24px;
    color: #cfb381;
}

.mobileNo{
    text-decoration: none;
    color: #d3d8db;
    transition: .3s;
}

.mobileNo:hover{
    color: #cfb381;
}
.contactUs__icons a{
    transition: .3s;
}

.contactUs__icons a:hover{
    transform: translateY(5px);
}

.map iframe{
    margin: 60px 0px 30px 20px;
    border-radius: 10px;
}

@media screen and (max-width:770px){
    .map iframe{
        width: 270px;
        height: 200px;
        display: block;
        margin: auto;
        margin-bottom: 30px;
    }
}

form input {
    min-width: 300px;
    display: block;
    margin: 10px 0px 20px 40px;
    height: 50px;
    padding: 20px;
    outline: none;
    background: linear-gradient(173deg, #0f1a2b, #122739,#2b4c66, transparent);
    border: none;
    transition: .3s;
    border-radius: 10px;
    color: #d3d8db;
}

@media screen and (max-width:800px){
    form input{
        margin: 10px auto 20px auto;
    }
    .text_field div{
        margin: 10px 0px 20px 20px;
    }
}

@media screen and (min-width:800px) and(max-width:1024px){
    .text_field div{
        margin: 10px 0px 20px 40px;
    }
}
textarea{
    min-width: 300px;
    /* display: flex;
    margin: auto; */
    padding: 20px;
    outline: none;
    background: linear-gradient(173deg, #0f1a2b, #122739,#2b4c66, transparent);
    border: none;
    transition: .3s;
    border-radius: 10px;
    color: #d3d8db;
    transition: .3s;
}

textarea:hover{
    border-bottom: 2px solid rgb(14, 186, 97);
    background:transparent;
}
.text_field div{
    margin: 10px 0px 20px 40px;
}

label{
    margin: 10px 0px 0px 30px;
    color: #d3d8db;
}
form input:hover{
    border-bottom: 2px solid rgb(14, 186, 97);
    background:transparent;
}

.contctImg{
    width: 300px;
    border-radius: 20px;
    display: block;
    margin: 40px auto 50px auto;
}

.submit{
    display: block;
    margin: 30px auto 30px auto;
    border-radius: 5px;
    width: 100px;
    height: 40px;
    border: none;
    outline: none;
    border-bottom: 2px solid rgb(14, 186, 97);
    background:transparent;
    color: #d3d8db;
}

.submit:hover{
    background-color: white;
    color: black;
}

.icon{
    color: #cfb381 !important;
    text-decoration: none !important;
}

.mail{
    color: #cfb381 !important;
    text-decoration: none !important;
    font-size: 16px;
}
