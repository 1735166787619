.product{
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
   display: flex;
}

@media screen and (max-width:1000px) and (max-height:800px){
    .product{
        display: block;
        margin: auto;
    }
}

@media screen and (min-width:330px) and (min-height:730px) {
    .product{
        display: flex;
    }
}

.product-img{
    margin-bottom: 30px;
}

canvas{
    display: block;
    vertical-align: bottom;
}
#tsparticles{
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
    opacity: 0.3;
}
.product-img img{
    width: 200px;
    margin: 0px 30px 20px 30px;
    transition: .3s;
    filter: drop-shadow(0px 5px 3px #252d34);
}

.product-img img:nth-last-child(5){
    border-radius: 50%;
}

@media screen and (min-width:767px) and (max-width:1023px){
    .product{
        margin-bottom: 40px;
        gap: 50px;
    } 
}

.product-img img:hover{
    transform: scale(1.2);
}

.product-img p{
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    font-family: 'Plus Jakarta Sans', sans-serif;
}