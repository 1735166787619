@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
}

body {
  background: #030e1e;
  font-family: 'Plus Jakarta Sans', sans-serif;
}

h1,h2,h3,h4,h5,h6{
  font-family: 'Plus Jakarta Sans', sans-serif;
}

h1,h2{
  font-size: 2.1rem;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-weight: 600;
}

a{
  text-decoration: none;
  color: unset;
}



