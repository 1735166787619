.testimonials__div{
    border: 2px solid #274572;
    border-radius: 10px;
    background: linear-gradient(176deg, #0c3775a8, transparent);
}


.comments{
    margin: 0px 40px 0px 40px;
    opacity: 5;
}

.carousel{
    height: 430px;
    background: linear-gradient(128deg, #164282, #397fbc,#506a7f, transparent);
    max-width: 20rem;
    margin: 50px auto 50px auto;
    border-radius: 20px;
}

@media screen and (min-width:800px){
    .carousel{
        max-width: 34rem;
        margin: 100px auto 100px auto;
        height: 400px;
    }
}
.user{
    margin: 50px 0px 30px 30px;
}

.userName{
    font-weight: 600;
}
.user i{
    padding: 5px;
}

.place{
    margin: 0px 0px 0px 30px;
    font-size: inherit;
}

.comments{
    font-weight: 600;
    color: #0000009e;
    letter-spacing: .2px;
    margin-bottom: 30px;
    font-size: 18px;
}

.carousel-control-prev{
    display: none;
}

.carousel-control-next{
    display: none;
}

.carousel-indicators [data-bs-target]{
    width: 5px;
    height: 5px;
    border-radius: 50%;
}

.carousel-indicators{
    bottom: -60px;
}